export function loadSlimFAQWidget() {
  const existingScript = document.querySelector('script[src="https://www.slimfaq.com/widget/widget.js"]')
  if (existingScript) {
    return
  }

  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true
  script.src = 'https://www.slimfaq.com/widget/widget.js'
  script.onload = () => {
    // Initialize Slimfaq with the desired configuration
    // @ts-expect-error maybe add module?
    window['Slimfaq'].init('online-incasso', {
      intercom: 'enable',
      button: 'Stel uw vraag aan de servicedesk',
      alignment: 'right'
    })
  }

  const firstScript = document.getElementsByTagName('script')[0]
  firstScript.parentNode?.insertBefore(script, firstScript)
}
