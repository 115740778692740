import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import type { FetchQueryOptions } from '@tanstack/react-query'

import { creditCaseAPi } from './api.constants'
import { DEFAULT_LIST_OPTIONS, unwrapResponse, useWrapMethod } from './api.internal'
import { buildFiltersQueryKey, ListOptions, queryClient, refetchQueries } from './api.methods'
import {
  CaseTimelineConnection,
  CaseTimelineStepTypeEnum,
  CreditCase,
  CreditCasesApiCreditCasesCreateOneRequest,
  CreditCasesApiCreditCasesDeleteOneRequest,
  CreditCasesApiCreditCasesForwardOneRequest,
  CreditCasesApiCreditCasesUpdateOneRequest,
  CreditCasesApiCreditCasesUpdateStateRequest, CreditCasesApiDebtorsUpdateOneRequest,
  CreditCasesApiInvoicesCreateOneRequest,
  CreditCasesApiInvoicesDeleteOneRequest,
  CreditCasesApiInvoicesUpdateOneRequest,
  CreditCasesApiMessagesCreateOneRequest,
  CreditCasesApiNotesCreateOneRequest,
  CreditCasesApiNotesDeleteOneRequest,
  CreditCasesApiPaymentsCreateOneRequest,
  CreditCasesApiPaymentsDeleteOneRequest,
  Debtor,
  FindCreditCase,
  Invoice,
  Message,
  Note,
  Payment
} from './open-api'

export function getListCreditCasesOptions(options: ListOptions = DEFAULT_LIST_OPTIONS, filters = {}) {
  return {
    queryKey: ['credit-cases', options.offset, options.limit, options.query, ...buildFiltersQueryKey(filters)],
    queryFn: () => unwrapResponse(creditCaseAPi.creditCasesQueryMany({ ...options, ...filters }))
  }
}

export function listCreditCases(options: ListOptions = DEFAULT_LIST_OPTIONS, filters = {}) {
  return queryClient.ensureQueryData(getListCreditCasesOptions(options, filters))
}

export function getCreditCaseQueryKey(id: number | string) {
  return ['credit-case', `${id}`]
}

export function getCreditCaseTimelineQueryKey(id: number | string) {
  return ['credit-case', `${id}`, 'timeline']
}

function getCreditCaseQueryOptions(id: number): FetchQueryOptions<FindCreditCase> {
  return {
    queryKey: getCreditCaseQueryKey(id),
    queryFn: () => unwrapResponse(creditCaseAPi.creditCasesFindById({ id }))
  }
}

export function useCreditCase() {
  const params = useParams<{ id: string }>()

  return useQuery(getCreditCaseQueryOptions(params.id as never))
}

export function useCreditCaseTimeline(enabled?: boolean) {
  const params = useParams<{ id: string }>()

  return useQuery({
    enabled,
    queryKey: getCreditCaseTimelineQueryKey(params.id as never),
    queryFn: () => unwrapResponse(creditCaseAPi.caseTimelineStepQueryMany({
      creditCaseId: params.id as never
    }))
  })
}

export function useUpdateCreditCaseMutation() {
  return useWrapMethod<
    CreditCasesApiCreditCasesUpdateOneRequest,
    CreditCase
  >(
    (params) => creditCaseAPi.creditCasesUpdateOne(params),
    (params) => getCreditCaseQueryKey(params.id)
  )
}

export function useUpdateCreditCaseDebtorMutation() {
  return useWrapMethod<
    CreditCasesApiDebtorsUpdateOneRequest,
    Debtor
  >(
    (params) => creditCaseAPi.debtorsUpdateOne(params),
    (params) => getCreditCaseQueryKey(params.creditCaseId)
  )
}

export function useCreateCreditCaseMutation() {
  return useWrapMethod<
    CreditCasesApiCreditCasesCreateOneRequest,
    CreditCase
  >(
    (params: CreditCasesApiCreditCasesCreateOneRequest) => creditCaseAPi.creditCasesCreateOne(params),
    async () => {
      refetchQueries(['credit-cases'])
    }
  )
}

export function useDeleteCreditCaseMutation() {
  return useWrapMethod<
    CreditCasesApiCreditCasesDeleteOneRequest,
    CreditCase
  >(
    (params: CreditCasesApiCreditCasesDeleteOneRequest) => creditCaseAPi.creditCasesDeleteOne(params),
    async () => {
      await refetchQueries(['credit-cases'])
    }
  )
}

export function useCreateCreditCaseInvoiceMutation() {
  return useWrapMethod<
    CreditCasesApiInvoicesCreateOneRequest,
    Invoice
  >(
    (params: CreditCasesApiInvoicesCreateOneRequest) => creditCaseAPi.invoicesCreateOne(params)
  )
}

export function useUpdateCreditCaseInvoiceMutation() {
  return useWrapMethod<
    CreditCasesApiInvoicesUpdateOneRequest,
    Invoice
  >(
    (params: CreditCasesApiInvoicesUpdateOneRequest) => creditCaseAPi.invoicesUpdateOne(params)
  )
}

export function useDeleteCreditCaseInvoiceMutation() {
  return useWrapMethod<
    CreditCasesApiInvoicesDeleteOneRequest,
    Invoice
  >(
    (params: CreditCasesApiInvoicesDeleteOneRequest) => creditCaseAPi.invoicesDeleteOne(params)
  )
}

export function useCreateCreditCaseNoteMutation() {
  return useWrapMethod<
    CreditCasesApiNotesCreateOneRequest,
    Note
  >(
    (params: CreditCasesApiNotesCreateOneRequest) => creditCaseAPi.notesCreateOne(params),
    (params, data) => getCreditCaseTimelineQueryKey(data.creditCaseId),
    // @ts-expect-error we do not update the cache of message
    (existing: CaseTimelineConnection, data: Note) => ({
      ...existing,
      nodes: [
        {
          ...data,
          showActions: true,
          type: CaseTimelineStepTypeEnum.Note
        },
        ...existing.nodes
      ]
    })
  )
}

export function useDeleteCreditCaseNoteMutation() {
  return useWrapMethod<
    CreditCasesApiNotesDeleteOneRequest,
    Note
  >(
    (params: CreditCasesApiNotesDeleteOneRequest) => creditCaseAPi.notesDeleteOne(params),
    (params) => getCreditCaseTimelineQueryKey(params.creditCaseId),
    // @ts-expect-error we do not update the cache of message
    (existing: CaseTimelineConnection, data: Note, params) => ({
      ...existing,
      nodes: existing.nodes.filter(({ id, type }) => (
        type !== CaseTimelineStepTypeEnum.Note || id !== params.id
      ))
    })
  )
}

export function useCreateCreditCaseMessageMutation() {
  return useWrapMethod<
    CreditCasesApiMessagesCreateOneRequest,
    Message
  >(
    (params: CreditCasesApiMessagesCreateOneRequest) => creditCaseAPi.messagesCreateOne(params)
  )
}

export function useCreditCaseUpdateStateMutation() {
  return useWrapMethod<
    CreditCasesApiCreditCasesUpdateStateRequest,
    void
  >(
    (params) => creditCaseAPi.creditCasesUpdateState(params),
    async (params) => {
      // Updates the credit case and the timeline
      await refetchQueries(getCreditCaseQueryKey(params.creditCaseId))
    }
  )
}

export function useCreateCreditCasePaymentMutation() {
  return useWrapMethod<
    CreditCasesApiPaymentsCreateOneRequest,
    Payment
  >(
    (params: CreditCasesApiPaymentsCreateOneRequest) => creditCaseAPi.paymentsCreateOne(params),
    async (params) => {
      // Updates the credit case and the timeline
      await refetchQueries(getCreditCaseQueryKey(params.creditCaseId))
    }
  )
}

export function useDeleteCreditCasePaymentMutation() {
  return useWrapMethod<
    CreditCasesApiPaymentsDeleteOneRequest,
    Payment
  >(
    (params: CreditCasesApiPaymentsDeleteOneRequest) => creditCaseAPi.paymentsDeleteOne(params),
    async (params) => {
      // Updates the credit case and the timeline
      await refetchQueries(getCreditCaseQueryKey(params.creditCaseId))
    }
  )
}

export function useForwardCreditCaseMutation() {
  return useWrapMethod<
    CreditCasesApiCreditCasesForwardOneRequest,
    void,
    FindCreditCase
  >(
    (params) => creditCaseAPi.creditCasesForwardOne(params),
    (params, data) => getCreditCaseQueryKey(params.creditCaseId),
    (existing, data, params) => ({
      ...existing,
      autoForward: true,
      bailiffId: params.forwardCreditCase.bailiffId
    })
  )
}
